import {
    CardHeader,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled,
    tableCellClasses
} from "@mui/material";
import { hatmTheme } from "../theme";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: hatmTheme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function Pricing(): JSX.Element {
    function createGroomingData(
        weight: string,
        bath: string,
        partialGroom: string,
        fullGroom: string,
    ) {
        return { weight, bath, partialGroom, fullGroom };
    }

    function createAddOnData(
        type: string,
        price: string,
    ) {
        return { type, price }
    }

    function createPawPackData(
        type: string,
        price: string,
    ) {
        return { type, price}
    }

    const groomingRows = [
        createGroomingData('0-14', '75', '85', '110'),
        createGroomingData('15-24', '85', '95', '125'),
        createGroomingData('25-34', '100', '115', '140'),
        createGroomingData('35-44', '115', '130', '150'),
        createGroomingData('45-54', '125', '150', '175'),
        createGroomingData('55-65', '135', '160', '200+'),
    ]

    const addOnRows = [
        createAddOnData('Additional Dog Nail Trim & Filing', '20'),
        createAddOnData('Flea & Tick Treatment', '20'),
        createAddOnData('Deshed', '20'),
        createAddOnData('Special Handling', '15'),
        createAddOnData('Scissor & Brush', '1/min'),
    ]

    const pawPackRows = [
        createPawPackData('Nail Trim with Filing', '35'),
        createPawPackData('Nail Trim with Filing + Paw Trim', '40'),
        createPawPackData('Nail Trim with Filing + Paw Trim + Ear Cleaning', '45'),
        createPawPackData('Tidy Me Pack: Nail Trim with Filing + Paws & Face Trim + Ear Cleaning', '50')
    ]

    return (
        <Grid
            container
            style={{ width: "100%", paddingBottom: 50, margin: "0 auto" }}
            justifyContent={"center"}
            alignContent={"center"}
            direction={"column"}
            gap={3}
        >
            <Grid xs={12} item>
                <CardHeader className="headerText" title="Groom Prices" sx={{ paddingTop: 0 }} />
                <TableContainer elevation={5} component={Paper} sx={{ borderRadius: "25px" }}>
                    <Table>
                        <TableHead>
                            <StyledTableCell>Weight</StyledTableCell>
                            <StyledTableCell align="right">Bath</StyledTableCell>
                            <StyledTableCell align="right">Partial Groom</StyledTableCell>
                            <StyledTableCell align="right">Full Groom</StyledTableCell>
                        </TableHead>
                        <TableBody>
                            {groomingRows.map((row) => (
                                <StyledTableRow
                                    key={row.weight}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <StyledTableCell component="th" scope="row">
                                        {row.weight}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">${row.bath}</StyledTableCell>
                                    <StyledTableCell align="right">${row.partialGroom}</StyledTableCell>
                                    <StyledTableCell align="right">${row.fullGroom}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid xs={12} lg={4} item>
                <CardHeader className="headerText" title="Add-Ons" />
                <TableContainer elevation={5} component={Paper} sx={{ borderRadius: "25px" }}>
                    <Table>
                        <TableHead>
                            <StyledTableCell>Type</StyledTableCell>
                            <StyledTableCell align="right">Price</StyledTableCell>
                        </TableHead>
                        <TableBody>
                            {addOnRows.map((row) => (
                                <StyledTableRow
                                    key={row.type}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <StyledTableCell component="th" scope="row">
                                        {row.type}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">${row.price}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid xs={12} lg={4} item>
                <CardHeader className="headerText" title="Pawdicure" sx={{paddingBottom: 0}} />
                <Typography fontStyle={"italic"} className="headerText">Includes Accessory & Cologne Spritz</Typography>
                <TableContainer elevation={5} component={Paper} sx={{ borderRadius: "25px" }}>
                    <Table>
                        <TableHead>
                            <StyledTableCell>Type</StyledTableCell>
                            <StyledTableCell align="right">Price</StyledTableCell>
                        </TableHead>
                        <TableBody>
                            {pawPackRows.map((row) => (
                                <StyledTableRow
                                    key={row.type}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <StyledTableCell component="th" scope="row">
                                        {row.type}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">${row.price}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}