import { Palette, PaletteOptions } from "@mui/material";

export const hatmBlue = "#00086e";
//export const hatmBlue = "#010521"; dark
export const hatmBlueDark = "#02072a";

const palette: PaletteOptions = {
    primary: {
        main: "#00086e"
    }
}

export default palette as Palette;