import {
    AppBar,
    Box,
    CssBaseline,
    Drawer,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Tab,
    Tabs,
    ThemeProvider,
    Toolbar,
    createTheme,
    useTheme
} from "@mui/material";
import textLogo from '../graphics/hatm-top-text.png';
import starsLeft from '../graphics/stars-left.png';
import starsRight from '../graphics/stars-right.png';
import { hatmBlue, hatmBlueDark } from "../theme/hatmPalette";
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function NavBar(): JSX.Element {
    const [mobileOpen, setMobileOpen] = useState(false);
    const navItems = [
        { title: 'Home', link: '/' },
        { title: 'Pricing', link: '/Pricing' },
        { title: 'Contact', link: '/Contact' }
    ];
    const drawerWidth = 150;

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const pathName = useLocation();

    const [value, setValue] = useState(pathName.pathname);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    let theme = useTheme();

    theme = createTheme(theme, {
        components: {
            MuiTab: {
                styleOverrides: {
                    root: {
                        "&.Mui-selected": {
                            color: hatmBlue,
                        }
                    }
                }
            }
        }
    })

    const drawer = (
        <Box marginTop={"75px"} onClick={handleDrawerToggle} sx={{ textAlign: 'center', overflow: "auto" }}>
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.title} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <Link style={{ textDecoration: "none", color: hatmBlue }} to={item.link}>
                                <ListItemText primary={item.title} />
                            </Link>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed"
                style={{ boxShadow: "none", background: hatmBlueDark }}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar disableGutters>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ paddingLeft: 3, mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Grid container
                        justifyContent={"center"}
                        sx={{ paddingRight: 5, mr: 2, display: { sm: 'none' } }}>
                        <Grid item>
                            <img src={textLogo} height={"75px"} alt="HATM Logo" />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        width={"100vw"}
                        bgcolor={"transparent"}
                        position={"fixed"}
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"center"}
                        top={0}
                        sx={{ clear: "both", padding: 0 }}
                    >
                        <Grid item display={{ xs: 'none', md: 'block' }}>
                            <img src={starsLeft} alt="stars" height={"125px"} />
                        </Grid>
                        <Grid item display={{ xs: 'none', sm: 'block' }}>
                            <img src={textLogo} alt="Howl at the moon" height={"125px"} />
                        </Grid>
                        <Grid item display={{ xs: 'none', md: 'block' }}>
                            <img src={starsRight} alt="stars" height={"125px"} />
                        </Grid>
                        <Box
                            sx={{
                                display: { xs: 'none', sm: 'block' },
                                bgcolor: "#f0f0f0",
                                minWidth: "75%",
                                textAlign: "center"
                            }}>
                            <ThemeProvider theme={theme}>
                                <Tabs
                                    action={ref => ref?.updateIndicator()}
                                    centered
                                    value={value}
                                    onChange={handleChange}
                                    TabIndicatorProps={{ sx: { bgcolor: hatmBlue } }}
                                >
                                    {navItems.map((item) => (
                                        <Tab
                                            key={item.title}
                                            component={Link}
                                            to={item.link}
                                            label={item.title}
                                            value={item.link}
                                        />
                                    ))}
                                </Tabs>
                            </ThemeProvider>
                        </Box>
                    </Grid>
                </Toolbar>
                <nav>
                    <Drawer
                        PaperProps={{ sx: { borderRadius: "0px 0px 50px 0px", height: "fit-content", paddingBottom: "25px" } }}
                        variant="temporary"
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            width: drawerWidth,
                            flexShrink: 0,
                            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                        }}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </nav>
            </AppBar>
            <Box sx={{
                '@media (min-width: 0px)': {
                    minHeight: "78px",
                },
                '@media (min-width: 600px)': {
                    minHeight: "220px",
                }
            }}></Box>
        </Box>
    )
}