import { Button, Card, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import emailjs from "@emailjs/browser";
import { hatmBlue } from "../theme/hatmPalette";
import poodle from '../graphics/hatm-poodle-cloud.png';

export default function Contact(): JSX.Element {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const onFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setFirstName(event.target.value);
    }

    const onLastNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setLastName(event.target.value);
    }

    const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setEmail(event.target.value);
    }

    const onPhoneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setPhone(event.target.value);
    }

    const onMessageChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setMessage(event.target.value);
    }

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();;

        // Your EmailJS service ID, template ID, and Public Key
        const serviceId = 'service_hatm';
        const templateId = 'template_hatm';
        const publicKey = 'Iw-xfUxqIzTYmZeOI';
        const fullName = `${firstName} ${lastName}`;

        // Create a new object that contains dynamic template params
        const templateParams = {
            from_name: fullName,
            from_email: email,
            from_phone: phone,
            message: message,
        };

        // Send the email using EmailJS
        emailjs.send(serviceId, templateId, templateParams, publicKey)
            .then((response) => {
                console.log('Email sent successfully!', response);
                setFirstName('');
                setLastName('');
                setEmail('');
                setPhone('');
                setMessage('');
                alert("Email successfully sent");
            })
            .catch((error) => {
                console.error('Error sending email:', error);
            });
    }

    const onCancel = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setMessage('');
    }

    return (
        <Grid container justifyContent={"center"} alignContent={"center"} gap={3} paddingTop={'1rem'}>
            <Grid item>
                <Card
                    elevation={5}
                    style={{
                        borderRadius: "20px",
                        maxWidth: "550px",
                        padding: "20px 30px",
                        margin: "0 auto",
                    }}>
                    <Grid container
                        direction="row"
                        justifyContent={"flex-end"}
                        alignItems={"flex-end"}
                        spacing={1}>
                        <Grid item display={{ xs: 'none', md: 'block' }} md={4} height={"100%"}>
                            <img src={poodle} style={{ maxWidth: "100%", transform: "scaleX(-1)" }} alt="Poodle" />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Grid item>
                                <Typography className="contactText" variant="h4">
                                    Contact Us!
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className="contactText" variant="h6" marginTop={"20px"}>
                                    Contact Howl At The Moon for service inquiries, pricing, to request a booking, or any other inquiries.
                                </Typography>
                                <Typography className="contactText" align="center" variant="h6" marginTop={"20px"}>
                                    {"Call or Text: "}<a href="tel:256-777-2357" className="mailLink">{"(256) 777-2357"}</a>
                                </Typography>
                                <Typography className="contactText" align="center" variant="h6" marginTop={"5px"}>
                                    {"E-Mail: "}
                                    <a href="mailto:info@hatmgrooming.com" className="mailLink">
                                        {"info@hatmgrooming.com"}
                                    </a>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid item>
                <Card elevation={5} style={{ borderRadius: "20px", maxWidth: "550px", padding: "20px 30px", margin: "0 auto" }}>
                    <form onSubmit={onSubmit}>
                        <Grid container spacing={1}>
                            <Grid xs={12} sm={6} item>
                                <TextField
                                    onChange={onFirstNameChange}
                                    value={firstName}
                                    placeholder="Enter first name"
                                    label="First Name"
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid xs={12} sm={6} item>
                                <TextField
                                    value={lastName}
                                    onChange={onLastNameChange}
                                    placeholder="Enter last name"
                                    label="Last Name"
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type="email"
                                    onChange={onEmailChange}
                                    value={email}
                                    placeholder="Enter email"
                                    label="Email"
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type="number"
                                    onChange={onPhoneChange}
                                    value={phone}
                                    placeholder="Enter phone number"
                                    label="Phone"
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Message"
                                    onChange={onMessageChange}
                                    value={message}
                                    multiline
                                    rows={4}
                                    placeholder="Type your message here"
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Button type="button" onClick={onCancel} variant="outlined" sx={{ color: hatmBlue }} fullWidth>Cancel</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button type="submit" variant="contained" sx={{ bgcolor: hatmBlue }} fullWidth>Submit</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Card>
            </Grid>
        </Grid >
    );
}