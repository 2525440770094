import './App.css';
import NavBar from './components/NavBar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Pricing from './components/Pricing';
import Contact from './components/Contact';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <NavBar />
        <div className="Content">
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/Pricing' element={<Pricing />} />
            <Route path='/Contact' element={<Contact />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
