import { Grid, Typography } from '@mui/material';
import logo from '../graphics/hatm.png'
import { hatmBlue } from '../theme/hatmPalette';

export default function Home() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ height: "100%" }}>
      <Grid xs={12} item >
        <img src={logo} alt="logo" height={400} />
        <Typography textAlign={"center"}>
          <h1 style={{padding: 0, margin: 0, color: hatmBlue}}>Mobile Dog Grooming</h1>
        </Typography>
      </Grid>
    </Grid>
  );
}